import type { Usuario } from '@/types/Autenticacao/Usuario'
import type { Cartorio } from '@/types/Autenticacao/Cartorio'

type UsuarioAtual = {
  usuario: Usuario
  cartorios: Cartorio[]
  cartorio: Cartorio
}

export const useUsuarioStore = defineStore('usuarioStore', {
  state: () => ({
    usuarioAtual: {
      usuario: {} as Usuario,
      cartorios: [] as Cartorio[],
      cartorio: {} as Cartorio,
      calendarioId: '',
    } as UsuarioAtual,
  }),
  actions: {
    getBgCor (cor: string): string {
      if (cor === 'red') {
        return '#f44336'
      }

      if (cor === 'purple') {
        return '#9c27b0'
      }

      if (cor === 'blue') {
        return '#2196f3'
      }

      if (cor === 'cyan') {
        return '#00bcd4'
      }

      if (cor === 'teal') {
        return '#009688'
      }

      if (cor === 'green') {
        return '#4caf50'
      }

      if (cor === 'orange') {
        return '#ff9800'
      }

      if (cor === 'brown') {
        return '#795548'
      }

      if (cor === 'bluegray') {
        return '#607d8b'
      }

      if (cor === 'black') {
        return '#000000'
      }

      return '#808080'
    },
  },
  getters: {
    cartorioAtual (state): Cartorio {
      return state.usuarioAtual.cartorio
    },
    cartorios (state): Cartorio[] {
      return state.usuarioAtual.cartorios
    },
  },
  persist: {
    paths: ['usuarioAtual'],
    storage: localStorage,
  },
})
